import React, { useEffect, useContext, useState } from "react";
import "./myFavorite.css";

import { iGlobalValues, iGlobalFts } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";
import { iChannels_info } from "../../Types/creatorStuffTypes";
import { iSponsors } from "../../Types/generalDataTypes";
import { ContextGlobalFts } from "../../Context/ContextGlobalFts";

import { MdOutlinePeopleAlt } from "react-icons/md";

const Index = () => {
  const [displaySponsors, setDisplaySponsors] = useState<iSponsors[] | null>();
  const [displayChannels, setDisplayChannels] = useState<
    iChannels_info[] | null
  >();

  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const {
    sponsors,
    YouTubers,
    setActiveComponent,
    setSelectedSponsor,
    setSelectedChannel,
    userFavorites,
  } = contextValues as iGlobalValues;

  const contextFts = useContext<Partial<iGlobalFts>>(ContextGlobalFts);
  const { fetchUserFavorites, formatSubscribers } = contextFts as iGlobalFts;

  useEffect(() => {
    fetchUserFavorites();
  }, []);

  useEffect(() => {
    if (userFavorites?.userFavoriteCreators && YouTubers) {
      const favoriteChannels = YouTubers.filter((youtuber) =>
        userFavorites?.userFavoriteCreators?.includes(youtuber.channel_id)
      );
      setDisplayChannels(favoriteChannels);
    }

    if (sponsors && userFavorites?.userFavoriteSponsors) {
      const favoriteSponsors = sponsors.filter((la_sponsor: iSponsors) =>
        userFavorites?.userFavoriteSponsors?.includes(la_sponsor.sponsor_id)
      );
      setDisplaySponsors(favoriteSponsors);
    }
  }, [userFavorites, YouTubers]);

  return (
    <section>
      <h2 className='_section_title'>My Favorites...</h2>
      <div className='_favorites_container'>
        <div className='_sponsor_container'>
          <h4 className='_sponsors_title'>Sponsors:</h4>
          {displaySponsors?.map((x) => (
            <div
              className='_sponsor_div'
              onClick={() => {
                setSelectedSponsor(x);
                setActiveComponent(1);
              }}>
              <img src={x.sponsor_logo} alt='' className='_sponsors_logos' />
              <p>{x.sponsor_name}</p>
            </div>
          ))}
        </div>
        <div className='_channel_container'>
          <h4 className='_channels_title'>Channels:</h4>
          {displayChannels?.map((c) => (
            <div className='_channel_div'>
              <div
                className='_channel_info'
                onClick={() => {
                  setSelectedChannel(c);
                  setActiveComponent(2);
                }}>
                <img src={c.channel_photo} alt='' className='_channels_img' />
                <span>
                  <h5>{c.channel_name}</h5>
                  <p className='_channel_handle'>{c.channel_username}</p>
                </span>
              </div>
              <div className='_channel_subs_container'>
                ( <MdOutlinePeopleAlt className='_subs_icon' />
                <p className='_channel_subs'>
                  {formatSubscribers(Number(c.subscribers))}
                </p>
                )
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Index;
