import axios from "axios";

import { loginInfo, iRegistrationInfo } from "../Types/creatorStuffTypes";
import { iSearchTerms, iWaiterInfo } from "../Types/generalDataTypes";

const SERVER_URL =
  process.env?.NODE_ENV === "production"
    ? "https://www.api.sponsorwave.com"
    : "http://localhost:8800";

const onSendWaiterInfo = async (waiterInfo: iWaiterInfo) => {
  return await axios.post(
    `${SERVER_URL}/collect_emails`,
    JSON.stringify({ waiterInfo }),
    {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
};

const onStoreChannel = async (channelId: string) => {
  return await axios.post(
    `${SERVER_URL}/store_channel`,
    JSON.stringify({ channelId }),
    {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
};

const onDeleteChannel = async (channelId: string) => {
  return await axios.get(`${SERVER_URL}/delete-channel`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: { channelId },
    withCredentials: true,
  });
};

const onStoreChannelException = async (channelId: string) => {
  return await axios.post(
    `${SERVER_URL}/store_channel_exception`,
    JSON.stringify({ channelId }),
    {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
};

const onRegistration = async (registrationData: iRegistrationInfo) => {
  return await axios.post(
    `${SERVER_URL}/register`,
    JSON.stringify(registrationData),
    {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
};

const onLogout = async () => {
  return await axios.get(`${SERVER_URL}/logout`, {
    withCredentials: true,
  });
};

const onAuthWithGoogle = async (token: string) => {
  return await axios.get(`${SERVER_URL}/auth/google/callback`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: { token },
    withCredentials: true,
  });
};

const onLogin = async (loginData: loginInfo) => {
  return await axios.post(`${SERVER_URL}/login`, JSON.stringify(loginData), {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });
};

const onVerifyEmail = async (token: string) => {
  return await axios.get(`${SERVER_URL}/verify-email`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: { token },
    withCredentials: true,
  });
};

const onRequestVerificationAgain = async (email: string) => {
  return await axios.post(
    `${SERVER_URL}/request-verification-again`,
    JSON.stringify({ email }),
    {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
};

const onRefreshToken = async () => {
  return await axios.get(`${SERVER_URL}/refresh-token`, {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });
};

const onCheckingOut = async (purchasing_price: string) => {
  return await axios.get(`${SERVER_URL}/check_out`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: { purchasing_price },
    withCredentials: true,
  });
};

const onSuccessCheckingOut = async (session_id: string) => {
  return await axios.get(`${SERVER_URL}/checkout_success`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: { session_id },
    withCredentials: true,
  });
};

const onGetCreatorInfo = async (username: string) => {
  return await axios.get(`${SERVER_URL}/creator`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: { username },
    withCredentials: true,
  });
};

const onGetSponsors = async () => {
  return await axios.get(`${SERVER_URL}/get-sponsors`, {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });
};

const onGetHomeStats = async () => {
  return await axios.get(`${SERVER_URL}/get-home-stats`, {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });
};

const onGetUserFavorites = async () => {
  return await axios.get(`${SERVER_URL}/get-user-favorites`, {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });
};

const onAddToFavoritesOrRemoveSponsors = async (sponsor_id: string) => {
  return await axios.get(`${SERVER_URL}/add-to-favorites-or-remove-sponsors`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: { sponsor_id },
    withCredentials: true,
  });
};

const onAddToFavoritesOrRemoveCreators = async (channel_id: string) => {
  return await axios.get(`${SERVER_URL}/add-to-favorites-or-remove-creators`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: { channel_id },
    withCredentials: true,
  });
};

const onGetSponsorsAndSponsoredVideos = async (channel_id: string) => {
  return await axios.get(`${SERVER_URL}/get-sponsors-sponsored-videos`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: { channel_id },
    withCredentials: true,
  });
};

const onGetSponsorStuff = async (sponsor_id: string) => {
  return await axios.get(`${SERVER_URL}/get-sponsors-stuff`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: { sponsor_id },
    withCredentials: true,
  });
};

const onGetSearchPlus = async (searchTerms: iSearchTerms) => {
  return await axios.get(`${SERVER_URL}/get-search-terms`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: { searchTerms },
    withCredentials: true,
  });
};

export {
  onSendWaiterInfo,
  onAuthWithGoogle,
  onLogin,
  onVerifyEmail,
  onRefreshToken,
  onRegistration,
  onStoreChannel,
  onSuccessCheckingOut,
  onCheckingOut,
  onRequestVerificationAgain,
  onGetCreatorInfo,
  onGetSponsors,
  onGetHomeStats,
  onAddToFavoritesOrRemoveSponsors,
  onGetSponsorsAndSponsoredVideos,
  onLogout,
  onStoreChannelException,
  onGetSponsorStuff,
  onGetUserFavorites,
  onAddToFavoritesOrRemoveCreators,
  onDeleteChannel,
  onGetSearchPlus,
};
