import React from "react";
import "./index.css";

import La_platform from "../../Assets/PSW.png";

const Index = () => {
  return (
    <section className='workSection'>
      <h5 className='workTitle'>Empowering Creators...</h5>
      <p className='_features_text'>
        {/* Understand the sponsorship habits of brands—how often they engage in
        sponsorships and their preferred types of creators. */}
        Helping creators make a living doing what they love while enabling
        companies to reach their potential customers!
        {/* Find sponsors that align with your content, increase your earnings, and
        focus on what you love—creating great content. */}
      </p>
      <div className='img_div3'>
        <ul className='_img_div3_list'>
          {/* <li>Maximize Monetization Opportunities.</li> */}
          {/* <li>Your Gateway to Perfect-Fit Sponsorship Deals.</li> */}
          <li>
            Discover the companies already on YouTube sponsoring creators.
          </li>
          {/* <li>
            Match with brands that align with your audience in just a few
            clicks.
          </li> */}
          <li>
            Connect with Sponsors That Resonate with Your Audience and Fully
            Monetize Your Content.
          </li>
          <li>
            Uncover sponsors you haven’t worked with before by checking
            collaborations of similar YouTubers.
          </li>
          {/* <li>
            Track sponsor collaborations with YouTubers and their frequency.
          </li> */}
        </ul>
        <img src={La_platform} alt='' className='_platform_img' />
      </div>
    </section>
  );
};

export default Index;
