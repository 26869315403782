import React, { useEffect, useContext } from "react";
import "../HomeBody/homeBody.css";
import { iSponsors } from "../../Types/generalDataTypes";
import { RiHeartFill, RiHeartLine } from "react-icons/ri";
import { onAddToFavoritesOrRemoveSponsors } from "../../API/leApi";
import { iGlobalValues, iGlobalFts } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";
import { ContextGlobalFts } from "../../Context/ContextGlobalFts";

type iSponsorProp = {
  sponsor: iSponsors;
};

const DataTable = ({ sponsor }: iSponsorProp): JSX.Element => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { userFavorites, setSelectedSponsor, setActiveComponent } =
    contextValues as iGlobalValues;
  const contextFts = useContext<Partial<iGlobalFts>>(ContextGlobalFts);
  const { fetchUserFavorites } = contextFts as iGlobalFts;

  const addToFavorite = async (sponsor_id: string) => {
    try {
      const res = await onAddToFavoritesOrRemoveSponsors(sponsor_id);
      if (res.status === 200) {
        await fetchUserFavorites();
      }
    } catch (error: any) {
      alert(error.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchUserFavorites();
  }, []);

  return (
    <tr>
      <td className='_first_row'>
        <div>
          {userFavorites?.userFavoriteSponsors?.some(
            (fav: string) => fav === sponsor.sponsor_id
          ) ? (
            <div>
              <RiHeartFill
                className='_add_to_favorite'
                onClick={() => addToFavorite(sponsor.sponsor_id)}
              />
            </div>
          ) : (
            <div>
              <RiHeartLine
                className='_add_to_favorite'
                onClick={() => addToFavorite(sponsor.sponsor_id)}
              />
            </div>
          )}
          <div
            className='_logo_name'
            onClick={() => {
              setActiveComponent(1);
              setSelectedSponsor(sponsor);
            }}>
            <img
              src={sponsor.sponsor_logo}
              alt=''
              className='_sponsors_logos'
            />
            <li> {sponsor.sponsor_name}</li>
          </div>
        </div>
      </td>
      <td className='_dashboard_industry'>{sponsor.sponsor_industry}</td>
      <td className='_dashboard_sponsorships'>
        {sponsor.num_of_sponsored_videos}
      </td>
      <td className='_tags_row'>
        <div>
          {sponsor.sponsor_tags.map((tag, index) => (
            <li key={index} className='_sponsor_tags'>
              {tag}
            </li>
          ))}
        </div>
      </td>
      <td className='_dashboard_pIdx'>$7</td>
    </tr>
  );
};

export default DataTable;
