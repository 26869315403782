import React, { useState, useContext, useEffect } from "react";
import "./searchPlus.css";

import Loader from "../../utils/Loader";
import { onGetSearchPlus } from "../../API/leApi";
import { iChannels_info } from "../../Types/creatorStuffTypes";
import { iSponsors } from "../../Types/generalDataTypes";

import { iGlobalFts } from "../../Types/ContextGlobalVars";
import { ContextGlobalFts } from "../../Context/ContextGlobalFts";

const Index = () => {
  let [isLoading, setIsLoading] = useState(false);
  let [searchPlusData, setSearchPlusData] = useState<
    iChannels_info[] | iSponsors[]
  >([]);
  const [searchTerms, setSearchTerms] = useState({
    toSearch: "",
    field: "",
  });

  const contextFts = useContext<Partial<iGlobalFts>>(ContextGlobalFts);
  const { formatSubscribers } = contextFts as iGlobalFts;

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchTerms((prevVal) => ({
      ...prevVal,
      toSearch: event.target.value,
    }));
  };

  let handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let res = await onGetSearchPlus(searchTerms);
      console.log(res);
      if (res.status === 200) {
        let { search_result } = res.data;
        setSearchPlusData(search_result as iChannels_info[] | iSponsors[]);
      }
    } catch (error: any) {
      alert(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   handleSubmit();
  // }, [])

  return (
    <section className='_search_plus_section'>
      {isLoading && <Loader />}
      <h1 className='_search_plus_title'>Search Plus...</h1>
      <p className='_search_plus_text'>
        Discover the unknowns collaborations of Youtube sean.
      </p>
      <form className='_search_containers' onSubmit={handleSubmit}>
        <select onChange={handleSelectChange} value={searchTerms.toSearch}>
          <option value='' disabled>
            Search about...
          </option>
          <option value='Sponsor'>Sponsor</option>
          <option value='Creator'>Creator</option>
        </select>
        <input
          type='text'
          placeholder={
            searchTerms.toSearch === "Sponsor"
              ? "Industry"
              : searchTerms.toSearch === "Creator"
              ? "Niche"
              : "Industry, Niche..."
          }
          onChange={(e) =>
            setSearchTerms((prevVal) => ({
              ...prevVal,
              field: e.target.value,
            }))
          }
        />
        <button className='_search_plus_btn'>Search</button>
      </form>
      <div className='_res_container'>
        {searchPlusData?.length > 0 ? (
          searchPlusData?.map((d) =>
            (d as iSponsors)?.sponsor_id ? (
              <div>
                <img src={(d as iSponsors)?.sponsor_logo} alt='' />
                <p>{(d as iSponsors)?.sponsor_name}</p>
              </div>
            ) : (
              <div>
                <img src={(d as iChannels_info)?.channel_photo} alt='' />
                <h5>{(d as iChannels_info)?.channel_name}</h5>
                <p>{(d as iChannels_info)?.channel_username}</p>
                <p>
                  {formatSubscribers(
                    Number((d as iChannels_info)?.subscribers)
                  )}
                </p>
              </div>
            )
          )
        ) : (
          <p className='_no_res_text'>
            Choose whether you want to search for sponsors or creators, then
            enter relevant keywords like industry or niche.{" "}
          </p>
        )}
      </div>
    </section>
  );
};

export default Index;
