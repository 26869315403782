import React, { useState, createContext, useEffect } from "react";
import { iGlobalValues } from "../Types/ContextGlobalVars";
import { onGetSponsors } from "../API/leApi";
import { iSponsors } from "../Types/generalDataTypes";
import { iChannels_info } from "../Types/creatorStuffTypes";
import { useAuth } from "./AuthProvider";

const savedPage: number =
  JSON.parse(localStorage.getItem("ActivePage") as string) || 0;

const ContextGlobalVars = createContext<iGlobalValues | {}>({});
const GlobalValuesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [reverificationSuccess, setReverificationSuccess] = useState("");
  const [serverErrMsg, setServerErrMsg] = useState("");
  const [closeNotice, setCloseNotice] = useState(false);

  const [refetchCreatorData, setRefetchCreatorData] = useState(false);
  const [sponsors, setSponsors] = useState<iSponsors[] | undefined>([]);
  const [YouTubers, setYouTubers] = useState<iChannels_info[] | null>([]);
  const [userFavorites, setUserFavorites] = useState({
    userFavoriteSponsors: [] as string[] | [],
    userFavoriteCreators: [] as string[] | [],
    user_id: "",
  });
  const [activeComponent, setActiveComponent] = useState(savedPage); // Track the active component index
  let [selectedSponsor, setSelectedSponsor] = useState<iSponsors | null>(null);
  const [selectedChannel, setSelectedChannel] = useState<iChannels_info | null>(
    null
  );

  let { state } = useAuth();
  useEffect(() => {
    (async () => {
      try {
        let res = await onGetSponsors();

        if (res.status === 200) {
          setSponsors(res.data.sponsors);
          setYouTubers(res.data?.youtube_channels);
        }
      } catch (err: any) {
        if (state.accessToken && state.customer_id && state.isAuthenticated)
          alert(
            `There was an error getting the requested info, try to refresh it again`
          );
      }
    })();
  }, [state.customer_id]);

  useEffect(() => {
    localStorage.setItem("ActivePage", JSON.stringify(activeComponent));
  }, [activeComponent]);

  return (
    <ContextGlobalVars.Provider
      value={{
        reverificationSuccess,
        setReverificationSuccess,
        serverErrMsg,
        setServerErrMsg,
        refetchCreatorData,
        setRefetchCreatorData,
        closeNotice,
        setCloseNotice,
        sponsors,
        YouTubers,
        setYouTubers,
        activeComponent,
        setActiveComponent,
        selectedSponsor,
        setSelectedSponsor,
        selectedChannel,
        setSelectedChannel,
        userFavorites,
        setUserFavorites,
      }}>
      {children}
    </ContextGlobalVars.Provider>
  );
};

export { GlobalValuesProvider, ContextGlobalVars };
